import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Button, DatePicker, Form, Input, InputNumber, message, Modal, Select, Spin, Switch, Upload } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

import TaskScheduleInput from '../TaskScheduleInput';
import './style.scss';

const API_URL = process.env.REACT_APP_API_URL;

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const CommonFormModal = (props) => {
  const { visible, fields, fetchObject, targetObjectId, initialValues, onOpen, onSubmit, ...rest } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState({});
  const [imageLoadingList, setImageLoadingList] = useState({});
  const [videoUrls, setVideoUrls] = useState({});
  const [videoLoadingList, setVideoLoadingList] = useState({});

  const resetForm = () => {
    form.resetFields();
    setImageUrls({});
    setImageLoadingList({});
    setVideoUrls({});
    setVideoLoadingList({});
  };

  useEffect(() => {
    if (visible) {
      if (targetObjectId === undefined) {
        resetForm();
      } else {
        setIsLoading(true);

        fetchObject(targetObjectId).then((object) => {
          if (object) {
            const imageFields = _.filter(fields, { type: 'UploadImage' });
            const datePickerFields = _.filter(fields, { type: 'DatePicker' });
            let imageObj = {};
            let modifiedObject = object;

            _.forEach(datePickerFields, (field) => {
              if (modifiedObject[field.name]) {
                modifiedObject[field.name] = moment(modifiedObject[field.name]);
              }
            });

            _.forEach(imageFields, (field) => {
              const { name, urlPropName } = field;
              imageObj[name] = _.get(object, urlPropName);
            });

            form.setFieldsValue(modifiedObject);
            setImageUrls((prevProps) => ({
              ...imageObj,
              ...prevProps,
            }));
          }
          setIsLoading(false);
        });
      }
    } else {
      resetForm();
    }
  }, [visible]);

  const beforeUploadImage = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const beforeUploadVideo = (file) => {
    const isLt100M = file.size / 1024 / 1024 < 200;
    if (!isLt100M) {
      message.error('Video must smaller than 200MB!');
    }

    return isLt100M;
  };

  const handleImageUploadChange = (name, info) => {
    if (info.file.status === 'uploading') {
      setImageLoadingList((prevProps) => ({ ...prevProps, [name]: true }));
      return;
    }
    if (info.file.status === 'done') {
      const { id, fileUrl } = info.file.response;
      setImageUrls((prevProps) => ({ ...prevProps, [name]: fileUrl }));
      setImageLoadingList((prevProps) => ({ ...prevProps, [name]: false }));
      form.setFieldsValue({ [name]: id });
    }
  };

  const handleVideoUploadChange = (name, info) => {
    if (info.file.status === 'uploading') {
      setVideoLoadingList((prevProps) => ({ ...prevProps, [name]: true }));
      return;
    }
    if (info.file.status === 'done') {
      const { id, fileUrl } = info.file.response;
      setVideoUrls((prevProps) => ({ ...prevProps, [name]: fileUrl }));
      setVideoLoadingList((prevProps) => ({ ...prevProps, [name]: false }));
      form.setFieldsValue({ [name]: id });
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Modal
      centered
      forceRender
      className="FormModal"
      width="60%"
      style={{ pointerEvents: isLoading ? 'none' : '' }}
      maskClosable={!isLoading}
      title={null}
      footer={null}
      visible={visible}
      {...rest}
    >
      <Spin spinning={isLoading} tip="Loading...">
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={(values) => {
            setIsLoading(true);
            onSubmit(values).then(() => setIsLoading(false));
          }}
        >
          {_.map(fields, (field, idx) => {
            const { type, name, label, text, required, options, inputProps } = field;
            if (type === 'PlainText') {
              return (
                <FormItem {...formItemLayout} key={`${idx}-${name}`} label={label} name={name}>
                  <span>{text}</span>
                </FormItem>
              );
            } else {
              let inputEl,
                valuePropName = 'value';

              switch (type) {
                case 'DatePicker':
                  inputEl = <DatePicker {...inputProps} />;
                  break;
                case 'Input':
                  inputEl = <Input {...inputProps} />;
                  break;
                case 'InputNumber':
                  inputEl = <InputNumber {...inputProps} />;
                  break;
                case 'Password':
                  inputEl = <Input.Password {...inputProps} />;
                  break;
                case 'Select':
                  inputEl = (
                    <Select {...inputProps}>
                      {_.map(options, (option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  );
                  break;
                case 'Switch':
                  inputEl = <Switch {...inputProps} />;
                  valuePropName = 'checked';
                  break;
                case 'TextArea':
                  inputEl = <TextArea rows={4} {...inputProps} />;
                  break;
                case 'UploadImage':
                  const uploadButton = (
                    <div className="img-upload-btn">
                      {imageLoadingList[name] ? <LoadingOutlined /> : <UploadOutlined />}
                      <div className="img-upload-btn">Upload</div>
                    </div>
                  );

                  inputEl = (
                    <Upload
                      listType="picture-card"
                      showUploadList={false}
                      action={`${API_URL}/file/upload-image`}
                      beforeUpload={beforeUploadImage}
                      onChange={(info) => handleImageUploadChange(name, info)}
                      {...inputProps}
                    >
                      {!_.isEmpty(imageUrls[name]) ? (
                        <img src={imageUrls[name]} alt="Banner" style={{ width: '100%' }} />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  );
                  break;
                case 'UploadVideo':
                  const uploadVideoButton = (
                    <div className="img-upload-btn">
                      {videoLoadingList[name] ? <LoadingOutlined /> : <UploadOutlined />}
                      <div className="img-upload-btn">Upload</div>
                    </div>
                  );

                  inputEl = (
                    <Upload
                      listType="picture-card"
                      showUploadList={false}
                      action={`${API_URL}/file/upload-video`}
                      beforeUpload={beforeUploadVideo}
                      onChange={(info) => handleVideoUploadChange(name, info)}
                      {...inputProps}
                    >
                      {!_.isEmpty(videoUrls[name]) ? (
                        <ReactPlayer url={videoUrls[name]} width="100%" height="100%" controls={true} playing={true} />
                      ) : (
                        uploadVideoButton
                      )}
                    </Upload>
                  );
                  break;
                case 'TaskSchedule':
                  inputEl = <TaskScheduleInput {...inputProps} />;
                  break;
                default:
              }

              return (
                <FormItem
                  {...formItemLayout}
                  key={`${idx}-${name}`}
                  label={label}
                  name={name}
                  valuePropName={valuePropName}
                  rules={[{ required, message: 'This field is required.' }]}
                >
                  {inputEl}
                </FormItem>
              );
            }
          })}
          <FormItem>
            <Button type="primary" size="large" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CommonFormModal;
