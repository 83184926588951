import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getUsers = (queries) =>
  fetch(`${API_URL}/users${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getUser = (userId) =>
  fetch(`${API_URL}/users/${userId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getUserProgresses = (userId, queries) =>
  fetch(`${API_URL}/users/${userId}/progresses${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getUserDailyMissions = (userId, queries) =>
  fetch(`${API_URL}/users/${userId}/daily-missions${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getUserPostFeedbacks = (userId, queries) =>
  fetch(`${API_URL}/users/${userId}/post-feedbacks${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const deleteUserProgresses = (userId, progressId) =>
  fetch(`${API_URL}/users/${userId}/progresses/${progressId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteProgresses = (userId, progressIds) =>
  fetch(`${API_URL}/users/${userId}/progresses/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ progressIds }),
  });

export const createUser = (user) =>
  fetch(`${API_URL}/users`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(user),
  });

export const updateUser = (userId, user) =>
  fetch(`${API_URL}/users/${userId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(user),
  });

export const deleteUser = (userId) =>
  fetch(`${API_URL}/users/${userId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteUsers = (userIds) =>
  fetch(`${API_URL}/users/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ userIds }),
  });
