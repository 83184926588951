import { React } from 'react';
import { Button } from 'antd';

import IrreversiblePopconfirmWrapper from '../IrreversiblePopconfirmWrapper';

const OperationButtonGroup = (props) => {
  const { enabledButtons, manageLabel, onManageButtonClick, onEditButtonClick, onDeleteButtonClick } = props;
  return (
    <div className="operation-btn-gp">
      {enabledButtons.includes('manage') && (
        <Button type="primary" onClick={onManageButtonClick}>
          {manageLabel || 'Manage'}{' '}
        </Button>
      )}
      {enabledButtons.includes('edit') && <Button onClick={onEditButtonClick}>Edit</Button>}
      {enabledButtons.includes('delete') && (
        <IrreversiblePopconfirmWrapper onConfirm={onDeleteButtonClick}>
          <Button type="primary" danger>
            Delete
          </Button>
        </IrreversiblePopconfirmWrapper>
      )}
    </div>
  );
};

OperationButtonGroup.defaultProps = {
  enabledButtons: [],
  manageRedirectUrl: '',
  onEditButtonClick: () => {},
  onDeleteButtonClick: () => {},
};

export default OperationButtonGroup;
