import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';
import * as publicApi from '../../api/public';
import logo from './logo.png';
import './style.scss';

const EmailVerificationPage = (props) => {
  const [isValidating, setIsValidating] = useState(true);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  const validateEmailVerificationCode = (code) => {
    setIsValidating(true);
    publicApi
      .validateEmailVerificationCode(code)
      .then((res) => res.json())
      .then((json) => {
        if (json.valid) {
          setIsCodeValid(true);
        }
        setIsValidating(false);
      });
  };

  useEffect(() => validateEmailVerificationCode(code), []);

  if (isValidating) {
    return <LoadingScreen loading={isValidating} loadingText="Validating..." />;
  } else {
    return (
      <div className="EmailVerificationPage">
        <p>✅</p>
        <>
          {isCodeValid
              ? <p>您的電子郵件已驗證。您現在可以登入您的帳戶。<br/> 您的电子邮件已验证。您现在可以登录您的账户。 <br/>Your email is verified. You can now login to your account.</p>
            : <p>不是有效的驗證碼。 <br/> 不是有效的验证码。 <br/> Not a valid code.</p>}
        </>
      </div>
    );
  }
};

export default EmailVerificationPage;
