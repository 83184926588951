import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { getJWT, removeJWT } from './utils/jwt';

import PrivateRoute from './routes/PrivateRoute';

import LoginPage from './scenes/LoginPage';
import DashboardPage from './scenes/DashboardPage';
import SchoolPage from './scenes/DashboardPage/scenes/SchoolPage';
import SchoolManagementPage from './scenes/DashboardPage/scenes/SchoolPage/scenes/SchoolManagementPage';
import ProgramPage from './scenes/DashboardPage/scenes/ProgramPage';
import ProgramManagementPage from './scenes/DashboardPage/scenes/ProgramPage/scenes/ProgramManagementPage';
import UserPage from './scenes/DashboardPage/scenes/UserPage';
import UserManagementPage from './scenes/DashboardPage/scenes/UserPage/scenes/UserManagementPage';
import PostPage from './scenes/DashboardPage/scenes/PostPage';
import OfficialPostPage from './scenes/DashboardPage/scenes/OfficialPostPage';
import GlobalNotificationPage from './scenes/DashboardPage/scenes/GlobalNotificationPage';
import VerificationEmailPage from './scenes/DashboardPage/scenes/VerificationEmailPage';
import AdminPage from './scenes/DashboardPage/scenes/AdminPage';
import RankingPage from './scenes/DashboardPage/scenes/RankingPage';
import StatPage from './scenes/DashboardPage/scenes/StatPage';
import PostSharePage from './scenes/PostSharePage';
import ResetPasswordPage from './scenes/ResetPasswordPage';
import EmailVerificationPage from './scenes/EmailVerificationPage';

const history = createBrowserHistory();

const DashboardSection = ({ match, ...rest }) => (
  <DashboardPage match={match} {...rest}>
    <Switch>
      <Route exact path={`${match.url}/schools`} component={SchoolPage} />
      <Route exact path={`${match.url}/schools/:schoolId`} component={SchoolManagementPage} />
      <Route exact path={`${match.url}/programs`} component={ProgramPage} />
      <Route exact path={`${match.url}/programs/:programId`} component={ProgramManagementPage} />
      <Route exact path={`${match.url}/users`} component={UserPage} />
      <Route exact path={`${match.url}/users/:userId`} component={UserManagementPage} />
      <Route exact path={`${match.url}/posts`} component={PostPage} />
      <Route exact path={`${match.url}/official-posts`} component={OfficialPostPage} />
      <Route exact path={`${match.url}/global-notifications`} component={GlobalNotificationPage} />
      <Route exact path={`${match.url}/verification-emails`} component={VerificationEmailPage} />
      <Route exact path={`${match.url}/admins`} component={AdminPage} />
      <Route exact path={`${match.url}/rankings`} component={RankingPage} />
      <Route exact path={`${match.url}/stats`} component={StatPage} />
      <Redirect to={`${match.url}/schools`} />
    </Switch>
  </DashboardPage>
);

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          const jwt = getJWT();

          if (jwt) {
            return <Redirect to="/dashboard" />;
          } else {
            removeJWT();
            return <Redirect to="/login" />;
          }
        }}
      />
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute path="/dashboard" component={DashboardSection} />
      <Route exact path="/posts/:postId" component={PostSharePage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/email-verification" component={EmailVerificationPage} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default AppRouter;
