import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getGlobalNotifications = () =>
  fetch(`${API_URL}/notifications/global-notifications`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createGlobalNotification = ({ message }) =>
  fetch(`${API_URL}/notifications/global-notifications`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ message }),
  });

export const deleteGlobalNotification = ({ message }) =>
  fetch(`${API_URL}/notifications/global-notifications`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ message }),
  });
