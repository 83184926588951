import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getVerificationEmails = () =>
  fetch(`${API_URL}/verification-emails`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const deleteVerificationEmail = ({ verificationEmailIds }) =>
  fetch(`${API_URL}/verification-emails`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ verificationEmailIds }),
  });
