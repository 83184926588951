import React from 'react';
import { CloseOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';

import IrreversiblePopconfirmWrapper from '../IrreversiblePopconfirmWrapper';
import './style.scss';

const TableButtonGroup = (props) => {
  const { enabledButtons, onCreate, onDelete, onApprove } = props;

  return (
    <div className="TableButtonGroup">
      <div className="btn-gp-left">
        {enabledButtons.includes('create') && (
          <div className="table-btn create-btn" onClick={onCreate}>
            <PlusOutlined /> Create
          </div>
        )}
        {enabledButtons.includes('approve') && (
          <div className="table-btn approve-btn" onClick={onApprove}>
            <CheckOutlined /> Approve
          </div>
        )}
      </div>
      <div className="btn-gp-right">
        {enabledButtons.includes('delete') && (
          <IrreversiblePopconfirmWrapper placement="topRight" onConfirm={onDelete}>
            <div className="table-btn delete-btn">
              <CloseOutlined /> Delete
            </div>
          </IrreversiblePopconfirmWrapper>
        )}
      </div>
    </div>
  );
};

TableButtonGroup.defaultProps = {
  enabledButtons: [],
  onCreate: () => {},
  onDelete: () => {},
};

export default TableButtonGroup;
