import { React } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';

import './style.scss';

const VideoPlayerModal = (props) => {
  const { videoUrl, ...rest } = props;

  return (
    <Modal className="VideoPlayerModal" width="60%" footer={null} {...rest} destroyOnClose>
      <ReactPlayer
        url={videoUrl}
        width="100%"
        height="100%"
        style={{ maxHeight: 500 }}
        controls={true}
        playing={true}
      />
    </Modal>
  );
};

export default VideoPlayerModal;
