import { UPDATE_USER_DATA, LOGOUT } from '../actionTypes';

const INITIAL_STATE = {
  id: undefined,
  name: '',
  userType: '',
  schoolId: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        userType: action.payload.userType,
        schoolId: action.payload.schoolId,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
