import React from 'react';
import { Layout, Tabs } from 'antd';

import PersonalRankingTable from './components/PersonalRankingTable';
import ClassRankingTable from './components/ClassRankingTable';
import FamilyRankingTable from './components/FamilyRankingTable';
import './style.scss';

const { TabPane } = Tabs;

const RankingPage = (props) => {
  return (
    <Layout>
      <div className="RankingPage">
        <div className="main-section">
          <h2>Ranking</h2>
          <Tabs>
            <TabPane key="Personal" tab="Personal">
              <PersonalRankingTable />
            </TabPane>
            <TabPane key="Class" tab="Class">
              <ClassRankingTable />
            </TabPane>
            <TabPane key="Family" tab="Family">
              <FamilyRankingTable />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};

export default RankingPage;
