const API_URL = process.env.REACT_APP_API_URL;

export const getPost = (postId) =>
  fetch(`${API_URL}/public/posts/${postId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const completeDailyMission = ({ dailyMissionType, userId, programCollectionId }) =>
  fetch(`${API_URL}/public/complete-daily-mission`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ dailyMissionType, userId, programCollectionId }),
  });

export const validatePasswordResetCode = (code) =>
  fetch(`${API_URL}/public/validate-password-reset-code`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
  });

export const validateEmailVerificationCode = (code) =>
fetch(`${API_URL}/public/email-verification`, {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({ code }),
});
