import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getPrograms = (queries) =>
  fetch(`${API_URL}/programs${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getProgramsActiveness = (queries) =>
  fetch(`${API_URL}/programs/activeness${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getProgram = (programId) =>
  fetch(`${API_URL}/programs/${programId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createProgram = (program) =>
  fetch(`${API_URL}/programs`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(program),
  });

export const updateProgram = (programId, program) =>
  fetch(`${API_URL}/programs/${programId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(program),
  });

export const deleteProgram = (programId) =>
  fetch(`${API_URL}/programs/${programId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeletePrograms = (programIds) =>
  fetch(`${API_URL}/programs/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ programIds }),
  });

export const addSchoolSchedule = (programId, content) =>
  fetch(`${API_URL}/programs/${programId}/add-school-schedule`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(content),
  });

export const editSchoolSchedule = (programId, content) =>
  fetch(`${API_URL}/programs/${programId}/edit-school-schedule`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(content),
  });
