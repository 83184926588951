import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Dropdown, Layout, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { removeJWT } from '../../utils/jwt';
import { logout } from '../../redux/actions/user';

import './style.scss';

const { Content, Header, Sider } = Layout;
const { Item: MenuItem } = Menu;

const DashboardPage = (props) => {
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.user.name);
  const userType = useSelector((state) => state.user.userType);
  const schoolId = useSelector((state) => state.user.schoolId);
  const { children, location } = props;
  const isSuperAdmin = userType === 'SUPERADMIN';
  const isSchoolAdmin = userType === 'SCHOOLADMIN';

  const handleLogout = (e) => {
    const { history } = props;
    removeJWT();
    dispatch(logout());
    history.push('/login');
  };

  const menu = (
    <Menu>
      <MenuItem key="logout">
        <div onClick={() => handleLogout()}>Logout</div>
      </MenuItem>
    </Menu>
  );

  return (
    <Layout className="DashboardPage">
      {isSuperAdmin && (
        <Sider width={170}>
          <Menu selectedKeys={[location.pathname]} mode="inline">
            <MenuItem key={`/dashboard/schools`}>
              <NavLink to={`/dashboard/schools`}>
                <span>Schools</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/admins`}>
              <NavLink to={`/dashboard/admins`}>
                <span>School Admins</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/programs`}>
              <NavLink to={`/dashboard/programs`}>
                <span>Programs</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/users`}>
              <NavLink to={`/dashboard/users`}>
                <span>Users</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/posts`}>
              <NavLink to={`/dashboard/posts`}>
                <span>Posts</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/official-posts`}>
              <NavLink to={`/dashboard/official-posts`}>
                <span>Official Posts</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/global-notifications`}>
              <NavLink to={`/dashboard/global-notifications`}>
                <span>Global Notifications</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/verification-emails`}>
              <NavLink to={`/dashboard/verification-emails`}>
                <span>Verification Emails</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/rankings`}>
              <NavLink to={`/dashboard/rankings`}>
                <span>Rankings</span>
              </NavLink>
            </MenuItem>
            <MenuItem key={`/dashboard/stats`}>
              <NavLink to={`/dashboard/stats`}>
                <span>Stats</span>
              </NavLink>
            </MenuItem>
          </Menu>
        </Sider>
      )}
      <Layout>
        <Header>
          <div className="profile-menu">
            <Dropdown overlay={menu}>
              <div className="username">
                {userName} <DownOutlined />
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content>{children}</Content>
      </Layout>
      {isSchoolAdmin && <Redirect to={`/dashboard/schools/${schoolId}`} />}
    </Layout>
  );
};

export default DashboardPage;
