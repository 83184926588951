import { React } from 'react';
import { Select } from 'antd';
import _ from 'lodash';

import './style.scss';

const { Option } = Select;
const OPTIONS = ['CHECKBOX', 'Q&A', 'REVIEW', 'COMPARISON', 'VIDEO'];

const TaskScheduleInput = ({ value, onChange, ...props }) => {
  const options = _.map(OPTIONS, (option) => (
    <Option key={option} value={option}>
      {option}
    </Option>
  ));

  const triggerChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const handleTaskChange = (cycle, week, newTask) => {
    let newTasks = _.map(value, (inner) => inner.slice());
    newTasks[cycle][week] = newTask;

    triggerChange(newTasks);
  };

  return (
    <table className="TaskScheduleInput" {...props}>
      <thead>
        <tr>
          <th>Week</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat&Sun</th>
        </tr>
      </thead>
      <tbody>
        {_.map(_.range(4), (cycle) => (
          <tr key={cycle}>
            <td>{cycle + 1}</td>
            {_.map(_.range(6), (week) => {
              return (
                <td key={`${cycle}-${week}`}>
                  <Select
                    dropdownMatchSelectWidth={false}
                    allowClear
                    placeholder="N/A"
                    value={value[cycle][week]}
                    onChange={(value) => handleTaskChange(cycle, week, value)}
                  >
                    {options}
                  </Select>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TaskScheduleInput;
