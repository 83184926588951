import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { Button, DatePicker, Layout, Select, Table } from 'antd';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import * as schoolApi from '../../../../../../api/school';
import * as rankingApi from '../../../../../../api/ranking';
import './style.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const PersonalRankingTable = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [mode, setMode] = useState('all');
  const [schools, setSchools] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [schoolPrograms, setSchoolPrograms] = useState([]);
  const [selectedSchoolProgramId, setSelectedSchoolProgramId] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const getSchools = () => {
    setIsLoading(true);
    schoolApi
      .getSchools()
      .then((res) => res.json())
      .then((json) => {
        setSchools(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getSchoolPrograms = (schoolId) => {
    setIsLoading(true);
    schoolApi
      .getSchoolPrograms(schoolId)
      .then((res) => res.json())
      .then((json) => {
        setSchoolPrograms(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getPersonalRanking = () => {
    setIsLoading(true);
    // const targetSchoolProgram = _.find(schoolPrograms, { id: selectedSchoolProgramId });
    const filteredSchoolPrograms = _.filter(schoolPrograms, (program) =>
      _.includes(selectedSchoolProgramId, program.id)
    );
    let startDate, endDate;

    if (filteredSchoolPrograms.length > 0) {
      startDate = _.orderBy(filteredSchoolPrograms, ['startDate'], ['asc'])[0].startDate;
      endDate = _.orderBy(filteredSchoolPrograms, ['endDate'], ['desc'])[0].endDate;
      rankingApi
        .getSchoolRanking({
          school_id: selectedSchoolId,
          program_id: selectedSchoolProgramId,
          from: _.isEmpty(dateRange) ? startDate : dateRange[0].toISOString(),
          to: _.isEmpty(dateRange) ? endDate : dateRange[1].toISOString(),
          class_coverage: mode,
        })
        .then((res) => res.json())
        .then((json) => {
          setDataSource(json);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => getSchools(), []);
  useEffect(() => {
    if (selectedSchoolId !== undefined) {
      setSelectedSchoolProgramId([]);
      setDataSource([]);
      getSchoolPrograms(selectedSchoolId);
    }
  }, [selectedSchoolId]);
  useEffect(() => {
    if (selectedSchoolId !== undefined && selectedSchoolProgramId.length > 0 && mode !== undefined) {
      getPersonalRanking();
    }
  }, [selectedSchoolProgramId, mode]);

  useDeepCompareEffectNoCheck(() => {
    if (selectedSchoolId !== undefined && selectedSchoolProgramId.length > 0 && mode !== undefined) {
      getPersonalRanking();
    }
  }, [dateRange]);

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      render: (value, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: 'Class',
      dataIndex: 'className',
      key: 'className',
      align: 'center',
      width: 80,
      render: (value) => value || '-',
    },
    {
      title: 'Class No.',
      dataIndex: 'classNumber',
      key: 'classNumber',
      align: 'center',
      width: 100,
      render: (value) => value || '-',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
    },
    {
      title: 'Level',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
    },
  ];

  return (
    <Layout>
      <div className="PersonalRankingTable">
        <div className="filter-control">
          <Select value={mode} onChange={(value) => setMode(value)}>
            <Option value="all">All</Option>
            <Option value="junior">P1-P3</Option>
            <Option value="senior">P4-P6</Option>
          </Select>
          <Select value={selectedSchoolId} onChange={(value) => setSelectedSchoolId(value)} placeholder="Select School">
            {_.map(schools, (school) => (
              <Option key={school.id} value={school.id}>
                {school.name}
              </Option>
            ))}
          </Select>
          <Select
            mode="multiple"
            value={selectedSchoolProgramId}
            onChange={(value) => setSelectedSchoolProgramId(value)}
            placeholder="Select Program"
            style={{ width: 250 }}
            showArrow
          >
            {_.map(schoolPrograms, (schoolProgram) => (
              <Option key={schoolProgram.id} value={schoolProgram.id}>
                {schoolProgram.name}
              </Option>
            ))}
          </Select>
          <RangePicker value={dateRange} onChange={(dates) => setDateRange(dates)} />
          <Button type="primary" disabled={dataSource.length === 0}>
            <CSVLink
              data={_.map(dataSource, (obj, idx) => ({
                rank: idx + 1,
                class: obj.className,
                classNumber: obj.classNumber,
                name: obj.name,
                score: obj.score,
                level: obj.rank,
              }))}
              filename="export.csv"
            >
              Export
            </CSVLink>
          </Button>
        </div>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}
          pagination={{
            showSizeChanger: false,
            onChange: (page) => setCurrentPage(page),
          }}
          scroll={{ x: 640 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/dashboard/users/${record.id}`);
              },
            };
          }}
        />
      </div>
    </Layout>
  );
};

export default withRouter(PersonalRankingTable);
