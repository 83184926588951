import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen';
import * as publicApi from '../../api/public';
import logo from './logo.png';
import './style.scss';

const ResetPasswordPage = (props) => {
  const [isValidating, setIsValidating] = useState(true);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  const validatePasswordResetCode = (code) => {
    setIsValidating(true);
    publicApi
      .validatePasswordResetCode(code)
      .then((res) => res.json())
      .then((json) => {
        if (json.valid) {
          setIsCodeValid(true);
        }
        setIsValidating(false);
      });
  };

  useEffect(() => validatePasswordResetCode(code), []);

  if (isValidating) {
    return <LoadingScreen loading={isValidating} loadingText="Validating..." />;
  } else {
    return (
      <div className="ResetPasswordPage">
        <p>✅</p>
        <p>
          {isCodeValid
            ? <>您的密码已重置。请检查您的电子邮件以获取新密码。<br/>您的密码已重置。请检查您的电子邮件以获取新密码。 <br/>Your password is reset. Please check your email to get the new password.</>
            : 'Not a valid code.'}
        </p>
      </div>
    );
  }
};

export default ResetPasswordPage;
