import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Layout, notification, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as schoolApi from '../../../../api/school';
import * as userApi from '../../../../api/user';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import './style.scss';
import queryStringBuilder from '../../../../utils/queryStringBuilder';

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const UserPage = (props) => {
  const query = useQuery();
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState();
  const [isUserFormModalVisible, setIsUserFormModalVisible] = useState(false);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  // const [currentPage, setCurrentPage] = useState(page);
  // const [searchInput, setSearchInput] = useState(search);
  const searchInput = query.get('search') || undefined;
  const currentPage = parseInt(query.get('page')) || 1;

  const generateQuery = (search, page) => {
    return queryStringBuilder({ search, page });
  };

  const getSchools = () => {
    schoolApi
      .getSchools({ simple: 1 })
      .then((res) => res.json())
      .then((json) => {
        setSchools(json);
      });
  };

  const getSchoolClasses = (schoolId) => {
    schoolApi
      .getSchoolClasses(schoolId)
      .then((res) => res.json())
      .then((json) => {
        setClasses(json);
      });
  };

  const getUsers = (page, search) => {
    setIsLoading(true);

    userApi
      .getUsers({ page, search })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  const deleteUser = (userId) => {
    setIsLoading(true);

    userApi
      .deleteUser(userId)
      .then((res) => res.json())
      .then((json) => {
        getUsers(currentPage, searchInput);
        setIsLoading(false);
      });
  };

  const batchDeleteusers = (userIds) => {
    setIsLoading(true);

    userApi
      .batchDeleteUsers(userIds)
      .then((res) => res.json())
      .then((json) => {
        getUsers(currentPage, searchInput);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSchools();
    getUsers(currentPage, searchInput);
  }, []);

  useEffect(() => {
    getUsers(currentPage, searchInput);
  }, [currentPage, searchInput]);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      align: 'center',
    },
    {
      title: 'School',
      dataIndex: ['school', 'name'],
      key: 'school',
      align: 'center',
      render: (value, record) => value || '-',
    },
    {
      title: 'Class',
      dataIndex: ['class', 'name'],
      key: 'class',
      align: 'center',
      width: 80,
      render: (value, record) => value || '-',
    },
    {
      title: 'Class No.',
      dataIndex: 'classNumber',
      key: 'classNumber',
      align: 'center',
      width: 100,
      render: (value, record) => value || '-',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 100,
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 250,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['edit', 'manage', 'delete']}
            onManageButtonClick={() => history.push(`/dashboard/users/${record.id}`)}
            onEditButtonClick={() => {
              getSchoolClasses(record.schoolId);
              setIsUserFormModalVisible(true);
              setSelectedUserId(record.id);
            }}
            onDeleteButtonClick={() => deleteUser(record.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="UserPage">
        <div className="main-section">
          <h2>Users</h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsUserFormModalVisible(true)}
            onDelete={() => batchDeleteusers(selectedRowKeys)}
          />
          <div className="search-input">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              defaultValue={searchInput}
              onPressEnter={(e) => {
                const newSearchInput = e.target.value;
                if (newSearchInput === searchInput) {
                  getUsers(currentPage, searchInput);
                } else {
                  history.push(`/dashboard/users${generateQuery(newSearchInput, 1)}`);
                }
              }}
            />
          </div>
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource.users}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              current: currentPage,
              total: dataSource.count,
              showSizeChanger: false,
              onChange: (page) => history.push(`/dashboard/users${generateQuery(searchInput, page)}`),
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'School',
            name: 'schoolId',
            type: 'Select',
            options: _.map(schools, (school) => ({
              label: school.name,
              value: school.id,
            })),
            inputProps: {
              onChange: (values) => getSchoolClasses(values),
            },
          },
          {
            label: 'Type',
            name: 'type',
            type: 'Select',
            required: true,
            options: [
              {
                label: 'STUDENT',
                value: 'STUDENT',
              },
              {
                label: 'FAMILY',
                value: 'FAMILY',
              },
            ],
          },
          {
            label: 'Class',
            name: 'classId',
            type: 'Select',
            options: _.map(classes, (classObj) => ({
              label: classObj.name,
              value: classObj.id,
            })),
          },
          {
            label: 'Class Number',
            name: 'classNumber',
            type: 'Input',
          },
          {
            label: 'Name',
            name: 'name',
            type: 'Input',
            required: true,
          },
          {
            label: 'Nickname',
            name: 'nickname',
            type: 'Input',
          },
          {
            label: 'Profile Image',
            name: 'profileImageId',
            type: 'UploadImage',
            urlPropName: 'profileImage.fileUrl',
          },
          {
            label: 'Username',
            name: 'username',
            type: 'Input',
            required: true,
          },
          {
            label: 'New Password',
            name: 'password',
            type: 'Password',
          },
        ]}
        targetObjectId={selectedUserId}
        visible={isUserFormModalVisible}
        fetchObject={(objectId) => userApi.getUser(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsUserFormModalVisible(false);
          setSelectedUserId(undefined);
        }}
        onSubmit={(values) =>
          (selectedUserId ? userApi.updateUser(selectedUserId, values) : userApi.createUser(values))
            .then((res) => res.json())
            .then((json) => {
              if (!json.error) {
                getUsers();
                setIsUserFormModalVisible(false);
                setSelectedUserId(undefined);
              } else {
                notification.error({
                  message: 'Failed to Create User',
                  description: json.error.message,
                });
              }
            })
        }
      />
    </Layout>
  );
};

export default UserPage;
