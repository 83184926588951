import React, { useState, useEffect } from 'react';
import { Layout, Switch, Table } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as schoolApi from '../../../../api/school';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import './style.scss';

const SchoolPage = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [isSchoolFormModalVisible, setIsSchoolFormModalVisible] = useState(false);

  const getSchools = () => {
    setIsLoading(true);

    schoolApi
      .getSchools()
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  const updateSchool = (schoolId, newValue) => {
    setIsLoading(true);

    schoolApi
      .updateSchool(schoolId, newValue)
      .then((res) => res.json())
      .then((json) => {
        getSchools();
      });
  };

  const deleteSchool = (schoolId) => {
    setIsLoading(true);

    schoolApi
      .deleteSchool(schoolId)
      .then((res) => res.json())
      .then((json) => {
        getSchools();
        setIsLoading(false);
      });
  };

  const batchDeleteschools = (schoolIds) => {
    setIsLoading(true);

    schoolApi
      .batchDeleteSchools(schoolIds)
      .then((res) => res.json())
      .then((json) => {
        getSchools();
        setIsLoading(false);
      });
  };

  useEffect(() => getSchools(), []);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: 'School Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: 100,
      sorter: (a, b) => a.code.localeCompare(b.code),
      render: (value, record) => value || '--',
    },
    // {
    //   title: 'Show Post?',
    //   dataIndex: 'isPublic',
    //   key: 'isPublic',
    //   align: 'center',
    //   width: 150,
    //   render: (value, record) => (
    //     <Switch
    //       checkedChildren={<CheckOutlined />}
    //       unCheckedChildren={<CloseOutlined />}
    //       checked={value}
    //       onChange={(value) => updateSchool(record.id, { isPublic: value })}
    //     />
    //   ),
    // },
    {
      title: 'Operations',
      dataIndex: '',
      align: 'center',
      width: 250,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['manage', 'edit', 'delete']}
            onManageButtonClick={() => history.push(`/dashboard/schools/${record.id}`)}
            onEditButtonClick={() => {
              setIsSchoolFormModalVisible(true);
              setSelectedSchoolId(record.id);
            }}
            onDeleteButtonClick={() => deleteSchool(record.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="SchoolPage">
        <div className="main-section">
          <h2>Schools</h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsSchoolFormModalVisible(true)}
            onDelete={() => batchDeleteschools(selectedRowKeys)}
          />
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'School Name',
            name: 'name',
            type: 'Input',
            required: true,
          },
          {
            label: 'Code',
            name: 'code',
            type: 'Input',
            required: true,
          },
          // {
          //   label: 'Show Post?',
          //   name: 'isPublic',
          //   type: 'Switch',
          //   required: true,
          // },
        ]}
        targetObjectId={selectedSchoolId}
        visible={isSchoolFormModalVisible}
        fetchObject={(objectId) => schoolApi.getSchool(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsSchoolFormModalVisible(false);
          setSelectedSchoolId(undefined);
        }}
        onSubmit={(values) =>
          (selectedSchoolId ? schoolApi.updateSchool(selectedSchoolId, values) : schoolApi.createSchool(values))
            .then((res) => res.json())
            .then(() => {
              getSchools();
              setIsSchoolFormModalVisible(false);
              setSelectedSchoolId(undefined);
            })
        }
      />
    </Layout>
  );
};

export default SchoolPage;
