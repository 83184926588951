import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getProgramCollections = (queries) =>
  fetch(`${API_URL}/program-collections${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getProgramCollection = (programCollectionId) =>
  fetch(`${API_URL}/program-collections/${programCollectionId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getProgramCollectionPrograms = (programCollectionId) =>
  fetch(`${API_URL}/program-collections/${programCollectionId}/programs`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createProgramCollection = (programCollection) =>
  fetch(`${API_URL}/program-collections`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(programCollection),
  });

export const updateProgramCollection = (programCollectionId, programCollection) =>
  fetch(`${API_URL}/program-collections/${programCollectionId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(programCollection),
  });

export const deleteProgramCollection = (programCollectionId) =>
  fetch(`${API_URL}/program-collections/${programCollectionId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteProgramCollections = (programCollectionIds) =>
  fetch(`${API_URL}/program-collections/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ programCollectionIds }),
  });
