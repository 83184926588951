import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { getJWT, setJWT } from '../../utils/jwt';
import * as authApi from '../../api/auth';
import logo from './logo.png';
import './style.scss';

const FormItem = Form.Item;

const LoginPage = (props) => {
  const [errorMessage, setErrorMessage] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const jwt = getJWT();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const { username, password } = values;
    setErrorMessage('');
    setIsLoggingIn(true);

    authApi
      .login({ username, password })
      .then((res) => res.json())
      .then((json) => {
        if (json.error) {
          setErrorMessage(json.error_description || json.error.message);
        } else {
          setJWT(json.access_token);
        }
        setIsLoggingIn(false);
      })
      .catch((err) => {
        console.error(err);

        setIsLoggingIn(false);
        setErrorMessage(err.response ? err.response.body.Message : err.message);
      });
  };

  if (jwt) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <div className="LoginPage">
        <div className="LoginPage-container">
          <div className="LoginPage-logo-container">
            <img className="LoginPage-logo" src={logo} />
            <p className="main-title">Reconnect SDG Dashboard</p>
          </div>
          <div className="LoginPage-form-container">
            <p className="error-message">{errorMessage}</p>
            <Form form={form} className="LoginPage-form" initialValues={{ isBelimoUser: true }} onFinish={handleSubmit}>
              <FormItem name="username">
                <Input prefix={<UserOutlined style={{ fontSize: 13 }} />} placeholder="Username" />
              </FormItem>
              <FormItem name="password">
                <Input prefix={<LockOutlined style={{ fontSize: 13 }} />} type="password" placeholder="Password" />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoggingIn}>
                  Log in
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    );
  }
};

export default LoginPage;
