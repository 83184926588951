import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import _ from 'lodash';

import * as schoolApi from '../../../../api/school';
import * as adminApi from '../../../../api/admin';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import './style.scss';

const AdminPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedAdminId, setSelectedAdminId] = useState();
  const [isAdminFormModalVisible, setIsAdminFormModalVisible] = useState(false);
  const [schools, setSchools] = useState([]);

  const getSchools = () => {
    schoolApi
      .getSchools({ simple: 1 })
      .then((res) => res.json())
      .then((json) => {
        setSchools(json);
      });
  };

  const getAdmins = () => {
    setIsLoading(true);

    adminApi
      .getAdmins()
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  const updateAdmin = (adminId, newValue) => {
    setIsLoading(true);

    adminApi
      .updateAdmin(adminId, newValue)
      .then((res) => res.json())
      .then((json) => {
        getAdmins();
      });
  };

  const deleteAdmin = (adminId) => {
    setIsLoading(true);

    adminApi
      .deleteAdmin(adminId)
      .then((res) => res.json())
      .then((json) => {
        getAdmins();
        setIsLoading(false);
      });
  };

  const batchDeleteadmins = (adminIds) => {
    setIsLoading(true);

    adminApi
      .batchDeleteAdmins(adminIds)
      .then((res) => res.json())
      .then((json) => {
        getAdmins();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSchools();
    getAdmins();
  }, []);

  const columns = [
    {
      title: 'School',
      dataIndex: ['school', 'name'],
      key: 'school',
      align: 'left',
      sorter: (a, b) => a.school.name.localeCompare(b.school.name),
    },
    {
      title: 'Admin Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 250,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['edit', 'delete']}
            onEditButtonClick={() => {
              setIsAdminFormModalVisible(true);
              setSelectedAdminId(record.id);
            }}
            onDeleteButtonClick={() => deleteAdmin(record.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="AdminPage">
        <div className="main-section">
          <h2>School Admins</h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsAdminFormModalVisible(true)}
            onDelete={() => batchDeleteadmins(selectedRowKeys)}
          />
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'School',
            name: 'schoolId',
            type: 'Select',
            options: _.map(schools, (school) => ({
              label: school.name,
              value: school.id,
            })),
          },
          {
            label: 'Name',
            name: 'name',
            type: 'Input',
            required: true,
          },
          {
            label: 'Username',
            name: 'username',
            type: 'Input',
            required: true,
          },
          {
            label: 'New Password',
            name: 'password',
            type: 'Password',
          },
        ]}
        targetObjectId={selectedAdminId}
        visible={isAdminFormModalVisible}
        fetchObject={(objectId) => adminApi.getAdmin(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsAdminFormModalVisible(false);
          setSelectedAdminId(undefined);
        }}
        onSubmit={(values) =>
          (selectedAdminId ? adminApi.updateAdmin(selectedAdminId, values) : adminApi.createAdmin(values))
            .then((res) => res.json())
            .then(() => {
              getAdmins();
              setIsAdminFormModalVisible(false);
              setSelectedAdminId(undefined);
            })
        }
      />
    </Layout>
  );
};

export default AdminPage;
