import React, { useState, useEffect } from 'react';
import { Layout, Popover, Select, Table, Tabs } from 'antd';
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as postApi from '../../../../api/post';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import VideoPlayerModal from '../../../../components/VideoPlayerModal';
import './style.scss';

const { Option } = Select;
const { TabPane } = Tabs;

const PostPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState();
  const [isPostFormModalVisible, setIsPostFormModalVisible] = useState(false);
  const [isVideoPlayerModalVisible, setIsVideoPlayerModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [status, setStatus] = useState('PENDING');

  const getPosts = (page, status, limit) => {
    setIsLoading(true);

    postApi
      .getPosts({ page, status, limit })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setDataSource(json);
        }
        setIsLoading(false);
      });
  };

  const updatePost = (postId, newValue) => {
    setIsLoading(true);

    postApi
      .updatePost(postId, newValue)
      .then((res) => res.json())
      .then((json) => {
        getPosts(currentPage, status, pageSize);
      });
  };

  const batchUpdatePosts = (postIds, newValue) => {
    setIsLoading(true);

    postApi
      .batchUpdatePosts(postIds, newValue)
      .then((res) => res.json())
      .then((json) => {
        getPosts(currentPage, status, pageSize);
        setIsLoading(false);
      });
  };

  const deletePost = (postId) => {
    setIsLoading(true);

    postApi
      .deletePost(postId)
      .then((res) => res.json())
      .then((json) => {
        getPosts(currentPage, status);
        setIsLoading(false);
      });
  };

  const batchDeletePosts = (postIds) => {
    setIsLoading(true);

    postApi
      .batchDeletePosts(postIds)
      .then((res) => res.json())
      .then((json) => {
        getPosts(currentPage, status, pageSize);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPosts(currentPage, status, pageSize);
  }, [currentPage, status, pageSize]);

  const imagePreview = (imgUrl) =>
    imgUrl ? (
      <Popover
        overlayStyle={{ maxWidth: '40%' }}
        content={<img style={{ maxHeight: '400px', width: '100%' }} src={imgUrl} alt="" />}
      >
        <SearchOutlined />
      </Popover>
    ) : (
      '-'
    );

  const columns = [
    {
      title: 'Program',
      dataIndex: ['program', 'name'],
      key: 'program',
      align: 'left',
    },
    {
      title: 'School',
      dataIndex: ['user', 'school', 'name'],
      key: 'school',
      align: 'center',
    },
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      key: 'student',
      align: 'center',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      align: 'left',
      render: (value) => value || '-',
    },
    {
      title: 'Image1',
      dataIndex: ['image1', 'fileUrl'],
      key: 'image1Id',
      align: 'center',
      width: 100,
      render: (value) => imagePreview(value),
    },
    {
      title: 'Image2',
      dataIndex: ['image2', 'fileUrl'],
      key: 'image2Id',
      align: 'center',
      width: 100,
      render: (value) => imagePreview(value),
    },
    {
      title: 'Video',
      dataIndex: ['video', 'fileUrl'],
      key: 'videoId',
      align: 'center',
      width: 100,
      render: (value) =>
        value ? (
          <SearchOutlined
            onClick={() => {
              setIsVideoPlayerModalVisible(true);
              setVideoUrl(value);
            }}
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (value, record) => (
        <Select value={value} onChange={(value) => updatePost(record.id, { status: value })}>
          <Option value="PENDING">PENDING</Option>
          <Option value="APPROVED">APPROVED</Option>
          <Option value="REJECTED">REJECTED</Option>
        </Select>
      ),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 150,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['delete']}
            onEditButtonClick={() => {
              setIsPostFormModalVisible(true);
              setSelectedPostId(record.id);
            }}
            onDeleteButtonClick={() => deletePost(record.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="PostPage">
        <div className="main-section">
          <h2>Posts</h2>
          <Tabs
            defaultActiveKey={status}
            onChange={(tab) => {
              setStatus(tab);
              setCurrentPage(1);
            }}
          >
            <TabPane key="PENDING" tab="PENDING"></TabPane>
            <TabPane key="APPROVED" tab="APPROVED"></TabPane>
            <TabPane key="REJECTED" tab="REJECTED"></TabPane>
          </Tabs>
          <TableButtonGroup
            enabledButtons={['approve', 'delete']}
            onApprove={() => batchUpdatePosts(selectedRowKeys, { status: 'APPROVED' })}
            onDelete={() => batchDeletePosts(selectedRowKeys)}
          />
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource.posts}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              current: currentPage,
              total: dataSource.count,
              showSizeChanger: true,
              pageSize,
              pageSizeOptions: [10, 20, 50],
              onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
              onChange: (page) => setCurrentPage(page),
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[]}
        targetObjectId={selectedPostId}
        visible={isPostFormModalVisible}
        fetchObject={(objectId) => postApi.getPost(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsPostFormModalVisible(false);
          setSelectedPostId(undefined);
        }}
        onSubmit={(values) =>
          (selectedPostId ? postApi.updatePost(selectedPostId, values) : postApi.createPost(values))
            .then((res) => res.json())
            .then(() => {
              getPosts(currentPage, status, pageSize);
              setIsPostFormModalVisible(false);
              setSelectedPostId(undefined);
            })
        }
      />
      <VideoPlayerModal
        visible={isVideoPlayerModalVisible}
        videoUrl={videoUrl}
        onCancel={() => {
          setIsVideoPlayerModalVisible(false);
          setVideoUrl('');
        }}
      />
    </Layout>
  );
};

export default PostPage;
