import { React, useState, useEffect } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useParams } from 'react-router-dom';
import { DatePicker, Layout, Popover, Table, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';

import * as userApi from '../../../../../../api/user';
import OperationButtonGroup from '../../../../../../components/OperationButtonGroup';
import VideoPlayerModal from '../../../../../../components/VideoPlayerModal';
import './style.scss';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const UserManagementPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dailyMissions, setDailyMissions] = useState([]);
  const [postFeedbacks, setPostFeedbacks] = useState([]);
  const [user, setUser] = useState({});
  const [dateRange, setDateRange] = useState([]);
  const [isVideoPlayerModalVisible, setIsVideoPlayerModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const { name } = user;
  const { userId } = useParams();

  const getUser = (userId) => {
    setIsLoading(true);

    userApi
      .getUser(userId)
      .then((res) => res.json())
      .then((json) => {
        setUser(json);
        setIsLoading(false);
      });
  };

  const getUserProgresses = (userId) => {
    setIsLoading(true);

    userApi
      .getUserProgresses(userId, {
        from: !_.isEmpty(dateRange) ? dateRange[0].toISOString() : undefined,
        to: !_.isEmpty(dateRange) ? dateRange[1].toISOString() : undefined,
      })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  const deleteUserProgress = (progressId) => {
    setIsLoading(true);

    userApi
      .deleteUserProgresses(userId, progressId)
      .then((res) => res.json())
      .then((json) => {
        getUserProgresses();
        setIsLoading(false);
      });
  };

  const getUserDailyMissions = (userId) => {
    setIsLoading(true);

    userApi
      .getUserDailyMissions(userId, {
        from: !_.isEmpty(dateRange) ? dateRange[0].toISOString() : undefined,
        to: !_.isEmpty(dateRange) ? dateRange[1].toISOString() : undefined,
      })
      .then((res) => res.json())
      .then((json) => {
        setDailyMissions(json);
        setIsLoading(false);
      });
  };

  const getUserPostFeedbacks = (userId) => {
    setIsLoading(true);

    userApi
      .getUserPostFeedbacks(userId, {
        from: !_.isEmpty(dateRange) ? dateRange[0].toISOString() : undefined,
        to: !_.isEmpty(dateRange) ? dateRange[1].toISOString() : undefined,
      })
      .then((res) => res.json())
      .then((json) => {
        setPostFeedbacks(json);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userId !== undefined) {
      getUser(userId);
      getUserProgresses(userId);
      getUserDailyMissions(userId);
      getUserPostFeedbacks(userId);
    }
  }, [userId]);

  useDeepCompareEffectNoCheck(() => {
    if (userId !== undefined) {
      getUserProgresses(userId);
      getUserDailyMissions(userId);
      getUserPostFeedbacks(userId);
    }
  }, [dateRange]);

  const imagePreview = (imgUrl) =>
    imgUrl ? (
      <Popover
        overlayStyle={{ maxWidth: '40%' }}
        content={<img style={{ maxHeight: '400px', width: '100%' }} src={imgUrl} alt="" />}
      >
        <SearchOutlined />
      </Popover>
    ) : (
      '-'
    );

  const columns = [
    {
      title: 'Program',
      dataIndex: 'programName',
      key: 'programName',
      align: 'left',
      render: (value, record) => (record.programCollectionName ? `${record.programCollectionName} - ${value}` : value),
    },
    {
      title: 'Week',
      dataIndex: 'week',
      key: 'week',
      align: 'center',
    },
    {
      title: 'Day',
      dataIndex: 'day',
      key: 'day',
      align: 'center',
    },
    {
      title: 'Task Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: 'Submitted Value',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Post ID',
      dataIndex: 'postId',
      key: 'postId',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Post Status',
      dataIndex: 'postStatus',
      key: 'postStatus',
      align: 'center',
      render: (value, record) => {
        if (record.type === 'REVIEW' || record.type === 'COMPARISION' || record.type === 'VIDEO') {
          return value;
        } else {
          return '-';
        }
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 150,
      render: (value, record) => {
        return (
          <OperationButtonGroup enabledButtons={['delete']} onDeleteButtonClick={() => deleteUserProgress(record.id)} />
        );
      },
    },
  ];

  const dailyMissionColumns = [
    {
      title: 'Program Collection',
      dataIndex: 'programCollectionName',
      key: 'programCollectionName',
      align: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      render: (value, record) => {
        if (record.maxCount) {
          return `${value} / ${record.maxCount}`;
        } else {
          return value;
        }
      },
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
  ];

  const postFeedbacksColumns = [
    {
      title: 'Program Collection',
      dataIndex: 'programCollectionName',
      key: 'programCollectionName',
      align: 'left',
      render: (value) => value || '-',
    },
    {
      title: 'Program',
      dataIndex: 'programName',
      key: 'programName',
      align: 'left',
    },
    {
      title: 'Post ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Likes',
      dataIndex: 'likes',
      key: 'likes',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      align: 'left',
      render: (value) => value || '-',
    },
    {
      title: 'Image1',
      dataIndex: ['image1', 'fileUrl'],
      key: 'image1Id',
      align: 'center',
      width: 100,
      render: (value) => imagePreview(value),
    },
    {
      title: 'Image2',
      dataIndex: ['image2', 'fileUrl'],
      key: 'image2Id',
      align: 'center',
      width: 100,
      render: (value) => imagePreview(value),
    },
    {
      title: 'Video',
      dataIndex: ['video', 'fileUrl'],
      key: 'videoId',
      align: 'center',
      width: 100,
      render: (value) =>
        value ? (
          <SearchOutlined
            onClick={() => {
              setIsVideoPlayerModalVisible(true);
              setVideoUrl(value);
            }}
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
  ];

  return (
    <Layout>
      <div className="UserManagementPage">
        <div className="main-section">
          <h2>{name}</h2>
          <RangePicker value={dateRange} onChange={(dates) => setDateRange(dates)} />
          <Tabs defaultActiveKey="game-progress">
            <TabPane tab="Game Progress" key="game-progress">
              <Table
                loading={isLoading}
                rowKey="id"
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  showSizeChanger: false,
                }}
              />
            </TabPane>
            <TabPane tab="Daily Missions" key="daily-missions">
              <Table
                loading={isLoading}
                rowKey="id"
                columns={dailyMissionColumns}
                dataSource={dailyMissions}
                pagination={{
                  showSizeChanger: false,
                }}
              />
            </TabPane>
            <TabPane tab="Post Feedbacks" key="post-feedbacks">
              <Table
                loading={isLoading}
                rowKey="id"
                columns={postFeedbacksColumns}
                dataSource={postFeedbacks}
                pagination={{
                  showSizeChanger: false,
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <VideoPlayerModal
        visible={isVideoPlayerModalVisible}
        videoUrl={videoUrl}
        onCancel={() => {
          setIsVideoPlayerModalVisible(false);
          setVideoUrl('');
        }}
      />
    </Layout>
  );
};

export default UserManagementPage;
