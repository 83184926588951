import React, { useState, useEffect } from 'react';
import { Input, Layout, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as userApi from '../../../../../../../../api/user';
import OperationButtonGroup from '../../../../../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../../../../../components/CommonFormModal';
import './style.scss';

const UserTable = (props) => {
  const { schoolId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [selectedUserId, setSelectedUserId] = useState();
  const [isUserFormModalVisible, setIsUserFormModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState();

  const getUsers = (page, search) => {
    setIsLoading(true);

    userApi
      .getUsers({ school_id: schoolId, page, search })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUsers(currentPage, searchInput);
  }, [currentPage, searchInput]);

  const columns = [
    {
      title: 'Class',
      dataIndex: ['class', 'name'],
      key: 'class',
      align: 'center',
      width: 100,
      render: (value, record) => value || '-',
    },
    {
      title: 'Class No.',
      dataIndex: 'classNumber',
      key: 'classNumber',
      align: 'center',
      width: 100,
      render: (value, record) => value || '-',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      align: 'center',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 150,
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 100,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['edit']}
            onEditButtonClick={() => {
              setIsUserFormModalVisible(true);
              setSelectedUserId(record.id);
            }}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="UserTable">
        <div className="search-input">
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            onPressEnter={(e) => {
              setCurrentPage(1);
              setSearchInput(e.target.value);
            }}
          />
        </div>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource.users}
          pagination={{
            current: currentPage,
            total: dataSource.count,
            showSizeChanger: false,
            onChange: (page) => setCurrentPage(page),
          }}
          scroll={{ x: 640 }}
        />
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'Nickname',
            name: 'nickname',
            type: 'Input',
          },
        ]}
        targetObjectId={selectedUserId}
        visible={isUserFormModalVisible}
        fetchObject={(objectId) => userApi.getUser(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsUserFormModalVisible(false);
          setSelectedUserId(undefined);
        }}
        onSubmit={(values) =>
          userApi
            .updateUser(selectedUserId, values)
            .then((res) => res.json())
            .then(() => {
              getUsers();
              setIsUserFormModalVisible(false);
              setSelectedUserId(undefined);
            })
        }
      />
    </Layout>
  );
};

export default UserTable;
