import React, { useState, useEffect } from 'react';
import { Layout, Switch, Table, Tabs } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';

import * as programApi from '../../../../api/program';
import * as programCollectionApi from '../../../../api/programCollection';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import './style.scss';

const { TabPane } = Tabs;

const ProgramPage = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCollectionRowKeys, setSelectedCollectionRowKeys] = useState([]);
  const [programCollections, setProgramCollections] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState();
  const [selectedProgramCollectionId, setSelectedProgramCollectionId] = useState();
  const [isProgramFormModalVisible, setIsProgramFormModalVisible] = useState(false);
  const [isProgramCollectionFormModalVisible, setIsProgramCollectionFormModalVisible] = useState(false);
  const [isProgramScheduleFormModalVisible, setIsProgramScheduleFormModalVisible] = useState(false);

  const getProgramCollections = () => {
    setIsLoading(true);

    programCollectionApi
      .getProgramCollections()
      .then((res) => res.json())
      .then((json) => {
        setProgramCollections(json);
        setIsLoading(false);
      });
  };

  const deleteProgramCollection = (programCollectionId) => {
    setIsLoading(true);

    programCollectionApi
      .deleteProgramCollection(programCollectionId)
      .then((res) => res.json())
      .then((json) => {
        getProgramCollections();
        setIsLoading(false);
      });
  };

  const batchDeleteProgramCollections = (programCollectionIds) => {
    setIsLoading(true);

    programCollectionApi
      .batchDeleteProgramCollections(programCollectionIds)
      .then((res) => res.json())
      .then((json) => {
        getProgramCollections();
        setIsLoading(false);
      });
  };

  const getPrograms = () => {
    setIsLoading(true);

    programApi
      .getPrograms()
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  const updateProgram = (programId, newValue) => {
    setIsLoading(true);

    programApi
      .updateProgram(programId, newValue)
      .then((res) => res.json())
      .then((json) => {
        getPrograms();
      });
  };

  const deleteProgram = (programId) => {
    setIsLoading(true);

    programApi
      .deleteProgram(programId)
      .then((res) => res.json())
      .then((json) => {
        getPrograms();
        setIsLoading(false);
      });
  };

  const batchDeleteprograms = (programIds) => {
    setIsLoading(true);

    programApi
      .batchDeletePrograms(programIds)
      .then((res) => res.json())
      .then((json) => {
        getPrograms();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProgramCollections();
    getPrograms();
  }, []);

  const columns = [
    {
      title: 'Program Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => {
        let aName = a.name,
          bName = b.name;
        if (a.type === 'FAMILY' && a.programCollection) {
          aName = `${a.programCollection.name} - ${a.name}`;
        }

        if (b.type === 'FAMILY' && b.programCollection) {
          bName = `${b.programCollection.name} - ${b.name}`;
        }
        return aName.localeCompare(bName);
      },
      render: (value, record) => {
        if (record.type === 'FAMILY' && record.programCollection) {
          return `${record.programCollection.name} - ${value}`;
        } else {
          return value;
        }
      },
    },
    // {
    //   title: 'Banner',
    //   dataIndex: ['bannerImage', 'fileUrl'],
    //   key: 'bannerImage',
    //   align: 'center',
    //   width: 250,
    //   render: (value, record) => (value ? <img className="banner-image" src={value} alt={record.name} /> : '-'),
    // },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: 100,
      render: (value) => {
        switch (value) {
          case 'SCHOOL':
            return '校內賽';
          case 'FAMILY':
            return '家庭賽';
          default:
            return value;
        }
      },
    },
    // {
    //   title: 'Show Post?',
    //   dataIndex: 'isPublic',
    //   key: 'isPublic',
    //   align: 'center',
    //   width: 150,
    //   render: (value, record) => (
    //     <Switch
    //       checkedChildren={<CheckOutlined />}
    //       unCheckedChildren={<CloseOutlined />}
    //       checked={value}
    //       onChange={(value) => updateProgram(record.id, { isPublic: value })}
    //     />
    //   ),
    // },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 280,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['manage', 'edit', 'delete']}
            manageLabel="Schedule"
            onManageButtonClick={() => {
              if (record.type === 'SCHOOL') {
                history.push(`/dashboard/programs/${record.id}`);
              } else {
                setIsProgramScheduleFormModalVisible(true);
                setSelectedProgramId(record.id);
              }
            }}
            onEditButtonClick={() => {
              setIsProgramFormModalVisible(true);
              setSelectedProgramId(record.id);
            }}
            onDeleteButtonClick={() => deleteProgram(record.id)}
          />
        );
      },
    },
  ];

  const programCollectionColumns = [
    {
      title: 'Program Collection Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (value) => moment(value).format('YYYY-MM-DD'),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 180,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['edit', 'delete']}
            onEditButtonClick={() => {
              setIsProgramCollectionFormModalVisible(true);
              setSelectedProgramCollectionId(record.id);
            }}
            onDeleteButtonClick={() => deleteProgramCollection(record.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="ProgramPage">
        <div className="main-section">
          <h2>Programs</h2>
          <Tabs>
            <TabPane key="program" tab="Program">
              <TableButtonGroup
                enabledButtons={['create', 'delete']}
                onCreate={() => setIsProgramFormModalVisible(true)}
                onDelete={() => batchDeleteprograms(selectedRowKeys)}
              />
              <Table
                loading={isLoading}
                rowKey="id"
                columns={columns}
                dataSource={dataSource}
                rowSelection={{
                  selectedRowKeys,
                  onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
                }}
                pagination={{
                  showSizeChanger: false,
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
            <TabPane key="program-collection" tab="Program Collection">
              <TableButtonGroup
                enabledButtons={['create', 'delete']}
                onCreate={() => setIsProgramCollectionFormModalVisible(true)}
                onDelete={() => batchDeleteProgramCollections(selectedCollectionRowKeys)}
              />
              <Table
                loading={isLoading}
                rowKey="id"
                columns={programCollectionColumns}
                dataSource={programCollections}
                rowSelection={{
                  selectedCollectionRowKeys,
                  onChange: (selectedRowKeys) => setSelectedCollectionRowKeys(selectedRowKeys),
                }}
                pagination={{
                  showSizeChanger: false,
                }}
                scroll={{ x: 640 }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'Type',
            name: 'type',
            type: 'Select',
            required: true,
            options: [
              {
                label: '校內賽',
                value: 'SCHOOL',
              },
              {
                label: '家庭賽',
                value: 'FAMILY',
              },
            ],
          },
          {
            label: 'Program Collection',
            name: 'programCollectionId',
            type: 'Select',
            options: _.map(programCollections, (programCollection) => ({
              label: programCollection.name,
              value: programCollection.id,
            })),
            inputProps: {
              allowClear: true,
            },
          },
          {
            label: 'Program Name',
            name: 'name',
            type: 'Input',
            required: true,
          },
          // {
          //   label: 'Banner Image',
          //   name: 'bannerImageId',
          //   type: 'UploadImage',
          //   urlPropName: 'bannerImage.fileUrl',
          // },
          // {
          //   label: 'Game Description',
          //   name: 'gameDescription',
          //   type: 'TextArea',
          // },
          {
            label: 'Max Score',
            name: 'maxScore',
            type: 'InputNumber',
            inputProps: {
              min: 0,
            },
          },
          {
            label: 'Number of Cycles',
            name: 'numberOfCycles',
            type: 'InputNumber',
            inputProps: {
              min: 1,
            },
          },
          {
            label: 'Tasks Per Cycle',
            name: 'tasksPerCycle',
            type: 'TaskSchedule',
            required: true,
          },
          // {
          //   label: 'Youtube ID',
          //   name: 'youtubeId',
          //   type: 'Input',
          // },
          {
            label: 'Asset Prefix',
            name: 'assetPrefix',
            type: 'Input',
          },
        ]}
        initialValues={{ tasksPerCycle: _.fill(Array(4), Array(6)) }}
        targetObjectId={selectedProgramId}
        visible={isProgramFormModalVisible}
        fetchObject={(objectId) => programApi.getProgram(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsProgramFormModalVisible(false);
          setSelectedProgramId(undefined);
        }}
        onSubmit={(values) =>
          (selectedProgramId ? programApi.updateProgram(selectedProgramId, values) : programApi.createProgram(values))
            .then((res) => res.json())
            .then(() => {
              getPrograms();
              setIsProgramFormModalVisible(false);
              setSelectedProgramId(undefined);
            })
        }
      />
      <CommonFormModal
        fields={[
          {
            label: 'Name',
            name: 'name',
            type: 'Input',
            required: true,
          },
          {
            label: 'Start Date',
            name: 'startDate',
            type: 'DatePicker',
            required: true,
          },
          {
            label: 'End Date',
            name: 'endDate',
            type: 'DatePicker',
            required: true,
          },
        ]}
        targetObjectId={selectedProgramCollectionId}
        visible={isProgramCollectionFormModalVisible}
        fetchObject={(objectId) => programCollectionApi.getProgramCollection(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsProgramCollectionFormModalVisible(false);
          setSelectedProgramCollectionId(undefined);
        }}
        onSubmit={(values) =>
          (selectedProgramCollectionId
            ? programCollectionApi.updateProgramCollection(selectedProgramCollectionId, values)
            : programCollectionApi.createProgramCollection(values)
          )
            .then((res) => res.json())
            .then(() => {
              getProgramCollections();
              setIsProgramCollectionFormModalVisible(false);
              setSelectedProgramCollectionId(undefined);
            })
        }
      />
      <CommonFormModal
        fields={[
          {
            label: 'Start Date',
            name: 'startDate',
            type: 'DatePicker',
            required: true,
          },
          {
            label: 'End Date',
            name: 'endDate',
            type: 'DatePicker',
            required: true,
          },
        ]}
        targetObjectId={selectedProgramId}
        visible={isProgramScheduleFormModalVisible}
        fetchObject={(objectId) => programApi.getProgram(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsProgramScheduleFormModalVisible(false);
          setSelectedProgramId(undefined);
        }}
        onSubmit={(values) =>
          programApi
            .updateProgram(selectedProgramId, values)
            .then((res) => res.json())
            .then(() => {
              setIsProgramScheduleFormModalVisible(false);
              setSelectedProgramId(undefined);
            })
        }
      />
    </Layout>
  );
};

export default ProgramPage;
