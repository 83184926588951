import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Button, Layout, Select, Table } from 'antd';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import * as programCollectionApi from '../../../../../../api/programCollection';
import * as rankingApi from '../../../../../../api/ranking';
import './style.scss';

const { Option } = Select;

const FamilyRankingTable = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [programCollections, setProgramCollections] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [selectedProgramCollectionId, setSelectedProgramCollectionId] = useState();
  const [selectedProgramId, setSelectedProgramId] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const getProgramCollections = (schoolId) => {
    setIsLoading(true);
    programCollectionApi
      .getProgramCollections(schoolId)
      .then((res) => res.json())
      .then((json) => {
        setProgramCollections(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getProgramCollectionPrograms = (programCollectionId) => {
    programCollectionApi
      .getProgramCollectionPrograms(programCollectionId)
      .then((res) => res.json())
      .then((json) => {
        setPrograms(json);
      });
  };

  const getFamilyRanking = () => {
    setIsLoading(true);
    const targetProgramCollection = _.find(programCollections, { id: selectedProgramCollectionId });

    rankingApi
      .getFamilyRanking({
        program_collection_id: selectedProgramCollectionId,
        from: targetProgramCollection.startDate,
        to: targetProgramCollection.endDate,
      })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getSingleFamilyRanking = () => {
    setIsLoading(true);
    const targetProgram = _.find(programs, { id: selectedProgramId });

    rankingApi
      .getSingleFamilyRanking({
        program_id: selectedProgramId,
        from: targetProgram.startDate,
        to: targetProgram.endDate,
      })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => getProgramCollections(), []);
  useEffect(() => {
    if (selectedProgramCollectionId !== undefined) {
      getFamilyRanking();
      getProgramCollectionPrograms(selectedProgramCollectionId);
      setSelectedProgramId();
    }
  }, [selectedProgramCollectionId]);

  useEffect(() => {
    if (selectedProgramCollectionId !== undefined && selectedProgramId !== undefined) {
      getSingleFamilyRanking();
    } else if (selectedProgramCollectionId !== undefined) {
      getFamilyRanking();
    }
  }, [selectedProgramId]);

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      render: (value, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: 'School',
      dataIndex: 'schoolName',
      key: 'schoolName',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Class',
      dataIndex: 'className',
      key: 'className',
      align: 'center',
      width: 80,
      render: (value) => value || '-',
    },
    {
      title: 'Class No.',
      dataIndex: 'classNumber',
      key: 'classNumber',
      align: 'center',
      width: 100,
      render: (value) => value || '-',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
    },
    {
      title: 'Level',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
    },
  ];

  return (
    <Layout>
      <div className="FamilyRankingTable">
        <div className="filter-control">
          <Select
            value={selectedProgramCollectionId}
            onChange={(value) => setSelectedProgramCollectionId(value)}
            placeholder="Select Program Collection"
          >
            {_.map(programCollections, (programCollection) => (
              <Option key={programCollection.id} value={programCollection.id}>
                {programCollection.name}
              </Option>
            ))}
          </Select>
          <Select
            value={selectedProgramId}
            onChange={(value) => setSelectedProgramId(value)}
            placeholder="(All Programs)"
            disabled={selectedProgramCollectionId === undefined}
            allowClear
          >
            {_.map(programs, (program) => (
              <Option key={program.id} value={program.id}>
                {program.name}
              </Option>
            ))}
          </Select>
          <Button type="primary" disabled={dataSource.length === 0}>
            <CSVLink
              data={_.map(dataSource, (obj, idx) => ({
                rank: idx + 1,
                school: obj.schoolName,
                class: obj.className,
                classNumber: obj.classNumber,
                name: obj.name,
                nickname: obj.nickname,
                username: obj.username,
                score: obj.score,
                level: obj.rank,
              }))}
              filename="export.csv"
            >
              Export
            </CSVLink>
          </Button>
        </div>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}
          pagination={{
            showSizeChanger: false,
            onChange: (page) => setCurrentPage(page),
          }}
          scroll={{ x: 640 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                history.push(`/dashboard/users/${record.id}`);
              },
            };
          }}
        />
      </div>
    </Layout>
  );
};

export default withRouter(FamilyRankingTable);
