import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import _ from 'lodash';

import * as schoolApi from '../../../../../../../../api/school';
import TableButtonGroup from '../../../../../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../../../../../components/CommonFormModal';
import './style.scss';

const ClassTable = (props) => {
  const { schoolId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isClassFormModalVisible, setIsClassFormModalVisible] = useState(false);

  const getClasses = () => {
    setIsLoading(true);

    schoolApi
      .getSchoolClasses(schoolId)
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const deleteClass = (classId) => {
    setIsLoading(true);

    schoolApi
      .deleteSchoolClass(schoolId, classId)
      .then((res) => res.json())
      .then((json) => {
        getClasses();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const batchDeleteClasses = (classIds) => {
    setIsLoading(true);

    schoolApi
      .batchDeleteSchoolClasses(schoolId, classIds)
      .then((res) => res.json())
      .then((json) => {
        getClasses();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => getClasses(), []);

  const columns = [
    {
      title: 'Class Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Operations',
      dataIndex: '',
      align: 'center',
      width: 100,
      render: (value, record) => {
        return <OperationButtonGroup enabledButtons={['delete']} onDeleteButtonClick={() => deleteClass(record.id)} />;
      },
    },
  ];

  return (
    <Layout>
      <div className="ClassTable">
        <TableButtonGroup
          enabledButtons={['create', 'delete']}
          onCreate={() => setIsClassFormModalVisible(true)}
          onDelete={() => batchDeleteClasses(selectedRowKeys)}
        />
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}
          pagination={{
            showSizeChanger: false,
          }}
          scroll={{ x: 640 }}
        />
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'Class Name',
            name: 'name',
            type: 'Input',
            required: true,
          },
        ]}
        visible={isClassFormModalVisible}
        onCancel={(e) => {
          setIsClassFormModalVisible(false);
        }}
        onSubmit={(values) =>
          schoolApi
            .createSchoolClass(schoolId, values)
            .then((res) => res.json())
            .then(() => {
              getClasses();
              setIsClassFormModalVisible(false);
            })
        }
      />
    </Layout>
  );
};

export default ClassTable;
