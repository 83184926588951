import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import moment from 'moment';

import * as verificationEmailApi from '../../../../api/verificationEmail';
import * as publicApi from '../../../../api/public';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import './style.scss';

const VerificationEmailPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const getVerificationEmails = () => {
    setIsLoading(true);

    verificationEmailApi
      .getVerificationEmails()
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setDataSource(json);
        }
        setIsLoading(false);
      });
  };

  const verifyVerificationEmail = (code) => {
    setIsLoading(true);

    publicApi
      .validateEmailVerificationCode(code)
      .then((res) => res.json())
      .then((json) => {
        getVerificationEmails();
        setIsLoading(false);
      });
  };

  const deleteVerificationEmail = (verificationEmailId) => {
    setIsLoading(true);

    verificationEmailApi
      .deleteVerificationEmail({ verificationEmailId })
      .then((res) => res.json())
      .then((json) => {
        getVerificationEmails();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getVerificationEmails();
  }, []);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'left',
      render: (value) => value || '-',
    },
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
      key: 'username',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 200,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['manage', 'delete']}
            onDeleteButtonClick={() => deleteVerificationEmail(record.id)}
            manageLabel="Approve"
            onManageButtonClick={() => verifyVerificationEmail(record.code)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="VerificationEmailPage">
        <div className="main-section">
          <h2>Verification Emails</h2>
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default VerificationEmailPage;
