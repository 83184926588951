import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Tabs } from 'antd';
import moment from 'moment';

import * as schoolApi from '../../../../../../api/school';
import UserTable from './components/UserTable';
import ClassTable from './components/ClassTable';
import SchoolPeronalRankingTable from './components/SchoolPeronalRankingTable';
import SchoolClassRankingTable from './components/SchoolClassRankingTable';
import './style.scss';

const { TabPane } = Tabs;

const SchoolManagementPage = (props) => {
  const [school, setSchool] = useState({});
  const [currentProgram, setCurrentProgram] = useState({});
  const { name } = school;
  const { name: programName, startDate, endDate } = currentProgram;
  const { schoolId } = useParams();

  const getSchool = (schoolId) => {
    schoolApi
      .getSchool(schoolId)
      .then((res) => res.json())
      .then((json) => {
        setSchool(json);
      });
  };

  const getCurrentProgram = (schoolId) => {
    schoolApi
      .getSchoolCurrentProgram(schoolId)
      .then((res) => res.json())
      .then((json) => {
        if (json && json.length > 0) {
          setCurrentProgram(json[0]);
        }
      });
  };

  useEffect(() => {
    if (schoolId !== undefined) {
      getSchool(schoolId);
      getCurrentProgram(schoolId);
    }
  }, [schoolId]);

  return (
    <Layout>
      <div className="SchoolManagementPage">
        <div className="main-section">
          <h2>
            {name}
            <br />
            {programName &&
              `Current Game: ${programName} (${moment(startDate).format('YYYY-MM-DD')} ~ ${moment(endDate).format(
                'YYYY-MM-DD'
              )})`}
          </h2>
          <Tabs>
            <TabPane key="Students" tab="Students">
              <UserTable schoolId={schoolId} />
            </TabPane>
            <TabPane key="Classes" tab="Classes">
              <ClassTable schoolId={schoolId} />
            </TabPane>
            <TabPane key="Personal Ranking" tab="Personal Ranking">
              <SchoolPeronalRankingTable schoolId={schoolId} />
            </TabPane>
            <TabPane key="Class Ranking" tab="Class Ranking">
              <SchoolClassRankingTable schoolId={schoolId} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};

export default SchoolManagementPage;
