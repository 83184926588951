import React, { useState, useEffect } from 'react';
import { Layout, Popover, Select, Table, Tabs } from 'antd';
import { CheckOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as postApi from '../../../../api/post';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import VideoPlayerModal from '../../../../components/VideoPlayerModal';
import './style.scss';

const OfficialPostPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedOfficialPostId, setSelectedOfficialPostId] = useState();
  const [isOfficialPostFormModalVisible, setIsOfficialPostFormModalVisible] = useState(false);
  const [isVideoPlayerModalVisible, setIsVideoPlayerModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const getOfficialPosts = () => {
    setIsLoading(true);

    postApi
      .getOfficialPosts()
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setDataSource(json);
        }
        setIsLoading(false);
      });
  };

  const updateOfficialPost = (officialPostId, newValue) => {
    setIsLoading(true);

    postApi
      .updateOfficialPost(officialPostId, newValue)
      .then((res) => res.json())
      .then((json) => {
        getOfficialPosts();
      });
  };

  const deleteOfficialPost = (officialPostId) => {
    setIsLoading(true);

    postApi
      .deleteOfficialPost(officialPostId)
      .then((res) => res.json())
      .then((json) => {
        getOfficialPosts();
        setIsLoading(false);
      });
  };

  const batchDeleteofficialPosts = (officialPostIds) => {
    setIsLoading(true);

    postApi
      .batchDeleteOfficialPosts(officialPostIds)
      .then((res) => res.json())
      .then((json) => {
        getOfficialPosts();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOfficialPosts();
  }, []);

  const imagePreview = (imgUrl) =>
    imgUrl ? (
      <Popover
        overlayStyle={{ maxWidth: '40%' }}
        content={<img style={{ maxHeight: '400px', width: '100%' }} src={imgUrl} alt="" />}
      >
        <SearchOutlined />
      </Popover>
    ) : (
      '-'
    );

  const columns = [
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      align: 'left',
      render: (value) => value || '-',
    },
    {
      title: 'Image',
      dataIndex: ['image', 'fileUrl'],
      key: 'imageId',
      align: 'center',
      width: 100,
      render: (value) => imagePreview(value),
    },
    {
      title: 'Video',
      dataIndex: ['video', 'fileUrl'],
      key: 'videoId',
      align: 'center',
      width: 100,
      render: (value) =>
        value ? (
          <SearchOutlined
            onClick={() => {
              setIsVideoPlayerModalVisible(true);
              setVideoUrl(value);
            }}
          />
        ) : (
          '-'
        ),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 200,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['edit', 'delete']}
            onEditButtonClick={() => {
              setIsOfficialPostFormModalVisible(true);
              setSelectedOfficialPostId(record.id);
            }}
            onDeleteButtonClick={() => deleteOfficialPost(record.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="OfficialPostPage">
        <div className="main-section">
          <h2>OfficialPosts</h2>
          <TableButtonGroup
            enabledButtons={['create', 'delete']}
            onCreate={() => setIsOfficialPostFormModalVisible(true)}
            onDelete={() => batchDeleteofficialPosts(selectedRowKeys)}
          />
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'Image',
            name: 'imageId',
            type: 'UploadImage',
            urlPropName: 'image.fileUrl',
          },
          {
            label: 'Video',
            name: 'videoId',
            type: 'UploadVideo',
            urlPropName: 'video.fileUrl',
          },
          {
            label: 'Text',
            name: 'text',
            type: 'TextArea',
          },
        ]}
        targetObjectId={selectedOfficialPostId}
        visible={isOfficialPostFormModalVisible}
        fetchObject={(objectId) => postApi.getOfficialPost(objectId).then((res) => res.json())}
        onCancel={(e) => {
          setIsOfficialPostFormModalVisible(false);
          setSelectedOfficialPostId(undefined);
        }}
        onSubmit={(values) =>
          (selectedOfficialPostId
            ? postApi.updateOfficialPost(selectedOfficialPostId, values)
            : postApi.createOfficialPost(values)
          )
            .then((res) => res.json())
            .then(() => {
              getOfficialPosts();
              setIsOfficialPostFormModalVisible(false);
              setSelectedOfficialPostId(undefined);
            })
        }
      />
      <VideoPlayerModal
        visible={isVideoPlayerModalVisible}
        videoUrl={videoUrl}
        onCancel={() => {
          setIsVideoPlayerModalVisible(false);
          setVideoUrl('');
        }}
      />
    </Layout>
  );
};

export default OfficialPostPage;
