import React, { useState, useEffect } from 'react';
import { Button, Layout, Select, Table } from 'antd';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import * as schoolApi from '../../../../../../../../api/school';
import * as rankingApi from '../../../../../../../../api/ranking';
import './style.scss';

const { Option } = Select;

const SchoolClassRankingTable = (props) => {
  const { schoolId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [mode, setMode] = useState('all');
  const [schoolPrograms, setSchoolPrograms] = useState([]);
  const [selectedSchoolProgramId, setSelectedSchoolProgramId] = useState();

  const getSchoolPrograms = (schoolId) => {
    setIsLoading(true);
    schoolApi
      .getSchoolPrograms(schoolId)
      .then((res) => res.json())
      .then((json) => {
        setSchoolPrograms(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getClassRanking = () => {
    setIsLoading(true);
    const targetSchoolProgram = _.find(schoolPrograms, { id: selectedSchoolProgramId });

    rankingApi
      .getSchoolRanking({
        type: 'class',
        school_id: schoolId,
        program_id: selectedSchoolProgramId,
        from: targetSchoolProgram.startDate,
        to: targetSchoolProgram.endDate,
        class_coverage: mode,
      })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSchoolPrograms(schoolId);
  }, []);
  useEffect(() => {
    if (selectedSchoolProgramId !== undefined && mode !== undefined) {
      getClassRanking();
    }
  }, [selectedSchoolProgramId, mode]);

  const columns = [
    {
      title: 'Rank',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 50,
      render: (value, record, index) => index + 1,
    },
    {
      title: 'Class',
      dataIndex: 'className',
      key: 'className',
      align: 'center',
      render: (value) => value || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      align: 'center',
    },
    {
      title: 'Level',
      dataIndex: 'rank',
      key: 'rank',
      align: 'center',
    },
  ];

  return (
    <Layout>
      <div className="SchoolClassRankingTable">
        <div className="filter-control">
          <Select value={mode} onChange={(value) => setMode(value)}>
            <Option value="all">All</Option>
            <Option value="junior">P1-P3</Option>
            <Option value="senior">P4-P6</Option>
          </Select>
          <Select
            value={selectedSchoolProgramId}
            onChange={(value) => setSelectedSchoolProgramId(value)}
            placeholder="Select Program"
          >
            {_.map(schoolPrograms, (schoolProgram) => (
              <Option key={schoolProgram.id} value={schoolProgram.id}>
                {schoolProgram.name}
              </Option>
            ))}
          </Select>
          <Button type="primary" disabled={dataSource.length === 0}>
            <CSVLink
              data={_.map(dataSource, (obj, idx) => ({
                rank: idx + 1,
                class: obj.className,
                classNumber: obj.classNumber,
                name: obj.name,
                score: obj.score,
                level: obj.rank,
              }))}
              filename="export.csv"
            >
              Export
            </CSVLink>
          </Button>
        </div>
        <Table
          loading={isLoading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}
          pagination={{
            showSizeChanger: false,
          }}
          scroll={{ x: 640 }}
        />
      </div>
    </Layout>
  );
};

export default SchoolClassRankingTable;
