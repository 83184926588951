import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import _ from 'lodash';
import ReactECharts from 'echarts-for-react';

import * as programApi from '../../../../api/program';
import './style.scss';

const UserPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const getProgramsActiveness = () => {
    setIsLoading(true);

    programApi
      .getProgramsActiveness()
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  const getOption = () => {
    return {
      title: {
        text: 'User Activeness',
      },
      tooltip: {
        trigger: 'axis',
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          splitLine: { show: false },
          data: _.map(dataSource, (data) => data.programName),
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: '{value}%'
          }
        },
      ],
      series: [
        {
          name: 'Activeness(%)',
          type: 'bar',
          data: _.map(dataSource, (data) => data.percentage * 100),
        },
      ],
    };
  };

  useEffect(() => {
    getProgramsActiveness();
  }, []);

  return (
    <Layout>
      <div className="UserPage">
        <div className="main-section">
          <h2>Stats</h2>
          <ReactECharts option={getOption()} notMerge={true} lazyUpdate={true} />
        </div>
      </div>
    </Layout>
  );
};

export default UserPage;
