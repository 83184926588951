import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getPosts = (queries) =>
  fetch(`${API_URL}/posts${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getPost = (postId) =>
  fetch(`${API_URL}/posts/${postId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createPost = (post) =>
  fetch(`${API_URL}/posts`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(post),
  });

export const updatePost = (postId, post) =>
  fetch(`${API_URL}/posts/${postId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(post),
  });

export const batchUpdatePosts = (postIds, post) =>
  fetch(`${API_URL}/posts/batch-update`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ postIds, ...post }),
  });

export const deletePost = (postId) =>
  fetch(`${API_URL}/posts/${postId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeletePosts = (postIds) =>
  fetch(`${API_URL}/posts/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ postIds }),
  });

export const getOfficialPosts = (queries) =>
  fetch(`${API_URL}/official-posts${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getOfficialPost = (officialPostId) =>
  fetch(`${API_URL}/official-posts/${officialPostId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createOfficialPost = (officialPost) =>
  fetch(`${API_URL}/official-posts`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(officialPost),
  });

export const updateOfficialPost = (officialPostId, officialPost) =>
  fetch(`${API_URL}/official-posts/${officialPostId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(officialPost),
  });

export const deleteOfficialPost = (officialPostId) =>
  fetch(`${API_URL}/official-posts/${officialPostId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteOfficialPosts = (officialPostIds) =>
  fetch(`${API_URL}/official-posts/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ officialPostIds }),
  });
