import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import _ from 'lodash';

import * as notificationApi from '../../../../api/notification';
import TableButtonGroup from '../../../../components/TableButtonGroup';
import OperationButtonGroup from '../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../components/CommonFormModal';
import './style.scss';

const GlobalNotificationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isGlobalNotificationFormModalVisible, setIsGlobalNotificationFormModalVisible] = useState(false);

  const getGlobalNotifications = () => {
    setIsLoading(true);

    notificationApi
      .getGlobalNotifications()
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setDataSource(json);
        }
        setIsLoading(false);
      });
  };

  const deleteGlobalNotification = (message) => {
    setIsLoading(true);

    notificationApi
      .deleteGlobalNotification({ message })
      .then((res) => res.json())
      .then((json) => {
        getGlobalNotifications();
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getGlobalNotifications();
  }, []);

  const columns = [
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      align: 'left',
      render: (value) => value || '-',
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 200,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['delete']}
            onDeleteButtonClick={() => deleteGlobalNotification(record.message)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="GlobalNotificationPage">
        <div className="main-section">
          <h2>Global Notifications</h2>
          <TableButtonGroup
            enabledButtons={['create']}
            onCreate={() => setIsGlobalNotificationFormModalVisible(true)}
          />
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
            }}
            pagination={{
              showSizeChanger: false,
            }}
            scroll={{ x: 640 }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'Message',
            name: 'message',
            type: 'TextArea',
          },
        ]}
        visible={isGlobalNotificationFormModalVisible}
        onCancel={(e) => {
          setIsGlobalNotificationFormModalVisible(false);
        }}
        onSubmit={(values) =>
          notificationApi
            .createGlobalNotification(values)
            .then((res) => res.json())
            .then(() => {
              getGlobalNotifications();
              setIsGlobalNotificationFormModalVisible(false);
            })
        }
      />
    </Layout>
  );
};

export default GlobalNotificationPage;
