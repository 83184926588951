import { getJWT } from '../utils/jwt';
import queryStringBuilder from '../utils/queryStringBuilder';

const API_URL = process.env.REACT_APP_API_URL;

export const getSchools = (queries) =>
  fetch(`${API_URL}/schools${queryStringBuilder(queries)}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getSchool = (schoolId) =>
  fetch(`${API_URL}/schools/${schoolId}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createSchool = (school) =>
  fetch(`${API_URL}/schools`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(school),
  });

export const updateSchool = (schoolId, school) =>
  fetch(`${API_URL}/schools/${schoolId}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(school),
  });

export const deleteSchool = (schoolId) =>
  fetch(`${API_URL}/schools/${schoolId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteSchools = (schoolIds) =>
  fetch(`${API_URL}/schools/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ schoolIds }),
  });

export const getSchoolClasses = (schoolId) =>
  fetch(`${API_URL}/schools/${schoolId}/classes`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const createSchoolClass = (schoolId, newClass) =>
  fetch(`${API_URL}/schools/${schoolId}/classes`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify(newClass),
  });

export const deleteSchoolClass = (schoolId, classId) =>
  fetch(`${API_URL}/schools/${schoolId}/classes/${classId}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const batchDeleteSchoolClasses = (schoolId, classIds) =>
  fetch(`${API_URL}/schools/${schoolId}/classes/batch-delete`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
    body: JSON.stringify({ classIds }),
  });

export const getSchoolCurrentProgram = (schoolId) =>
  fetch(`${API_URL}/schools/${schoolId}/current-school-program`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });

export const getSchoolPrograms = (schoolId) =>
  fetch(`${API_URL}/schools/${schoolId}/school-programs`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });
