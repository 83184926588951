import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Table } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import * as programApi from '../../../../../../api/program';
import * as schoolApi from '../../../../../../api/school';
import * as schoolProgramApi from '../../../../../../api/schoolProgram';
import OperationButtonGroup from '../../../../../../components/OperationButtonGroup';
import CommonFormModal from '../../../../../../components/CommonFormModal';
import './style.scss';

const ProgramManagementPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [program, setProgram] = useState({});
  const [selectedSchoolId, setSelectedSchoolId] = useState();
  const [isSchoolProgramFormModalVisible, setIsSchoolProgramFormModalVisible] = useState(false);
  const { name } = program;
  const { programId } = useParams();
  const selectedSchool = _.find(dataSource, { id: selectedSchoolId }) || {};

  const getProgram = (programId) => {
    setIsLoading(true);

    programApi
      .getProgram(programId)
      .then((res) => res.json())
      .then((json) => {
        setProgram(json);
        setIsLoading(false);
      });
  };

  const getSchoolPrograms = (programId) => {
    setIsLoading(true);

    schoolApi
      .getSchools({ simple: true, program_id: programId })
      .then((res) => res.json())
      .then((json) => {
        setDataSource(json);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (programId !== undefined) {
      getProgram(programId);
      getSchoolPrograms(programId);
    }
  }, [programId]);

  const columns = [
    {
      title: 'School Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      width: 150,
      render: (value) => (value ? moment(value).format('YYYY-MM-DD') : '-'),
    },
    {
      title: 'Operation',
      dataIndex: '',
      align: 'center',
      width: 150,
      render: (value, record) => {
        return (
          <OperationButtonGroup
            enabledButtons={['edit']}
            onEditButtonClick={() => {
              setIsSchoolProgramFormModalVisible(true);
              setSelectedSchoolId(record.id);
            }}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <div className="ProgramManagementPage">
        <div className="main-section">
          <h2>{name}</h2>
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={{
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
      <CommonFormModal
        fields={[
          {
            label: 'School',
            type: 'PlainText',
            text: selectedSchool.name,
          },
          {
            label: 'Start Date',
            name: 'startDate',
            type: 'DatePicker',
            required: true,
          },
          {
            label: 'End Date',
            name: 'endDate',
            type: 'DatePicker',
            required: true,
          },
        ]}
        targetObjectId={selectedSchoolId}
        visible={isSchoolProgramFormModalVisible}
        fetchObject={(objectId) =>
          schoolProgramApi.getSchoolProgram({ program_id: programId, school_id: objectId }).then((res) => res.json())
        }
        onCancel={(e) => {
          setIsSchoolProgramFormModalVisible(false);
          setSelectedSchoolId(undefined);
        }}
        onSubmit={(values) =>
          (selectedSchool.startDate
            ? programApi.editSchoolSchedule(programId, { schoolId: selectedSchoolId, ...values })
            : programApi.addSchoolSchedule(programId, { schoolId: selectedSchoolId, ...values })
          )
            .then((res) => res.json())
            .then(() => {
              getSchoolPrograms(programId);
              setIsSchoolProgramFormModalVisible(false);
              setSelectedSchoolId(undefined);
            })
        }
      />
    </Layout>
  );
};

export default ProgramManagementPage;
