import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const login = ({ username, password }) =>
  fetch(`${API_URL}/auth/admin-login`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });

export const authenticate = () =>
  fetch(`${API_URL}/auth/authenticate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`,
    },
  });
