import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

import * as publicApi from '../../api/public';
import AppStoreBadge from './app_store_badge.svg';
import './style.scss';

const PostSharePage = (props) => {
  const [post, setPost] = useState({});
  const { program, user, text, image1, image2, video } = post;
  const { postId } = useParams();
  const [errorMessage, setErrorMessage] = useState();

  const getPost = (postId) => {
    publicApi
      .getPost(postId)
      .then((res) => res.json())
      .then((json) => {
        if (!json.error) {
          setPost(json);
          if (json.program && json.program.programCollectionId) {
            publicApi.completeDailyMission({
              dailyMissionType: 'SHARE_VIDEO',
              userId: json.userId,
              programCollectionId: json.program.programCollectionId,
            });
          }
        } else {
          setErrorMessage(json.error.message);
        }
      });
  };

  const renderPostContent = () => {
    if (video) {
      return <ReactPlayer url={video.fileUrl} width="100%" height="100%" controls={true} playing={true} />;
    } else if (image1 && image2) {
      return (
        <div className="image-comparision">
          <div className="comparision-block">
            <div>Before:</div>
            <img src={image1.fileUrl} alt="" />
          </div>
          <div className="comparision-block">
            <div>After:</div>
            <img src={image2.fileUrl} alt="" />
          </div>
        </div>
      );
    } else if (image1) {
      return <img src={image1.fileUrl} alt="" />;
    }
  };

  const renderUser = () => {
    if (user) {
      return (
        <div className="user-profile">
          <img src={user.profileImage && user.profileImage.fileUrl} alt="" />
          <div className="username">{user && (user.nickname || user.name)}</div>
        </div>
      );
    }
  };

  const renderPost = () => {
    return (
      <div className="post-container">
        <div className="post-type">
          {program && (program.type === 'SCHOOL' ? '校內賽' : '家庭賽')} | {program && program.name}
        </div>
        <div className="post-content-container">
          {renderPostContent()}
          <div className="post-text">{text}</div>
        </div>
        {renderUser()}
      </div>
    );
  };

  useEffect(() => getPost(postId), []);

  return (
    <div className="PostSharePage">
      {errorMessage ? <div className="error-message">{errorMessage}</div> : renderPost()}
      <div className="additional-text">
        我參加咗”睇現環保”計劃，每日參與環保挑戰任務，然後紀錄於手機應用程式儲積分贏獎品，你都快啲下載程式，一齊嚟支持環保啦！
      </div>
      <div className="badge-group">
        <a href="https://play.google.com/store/apps/details?id=com.futureimpactlab.sdg&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className="badge"
            alt="立即下載 Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/zh-hk_badge_web_generic.png"
          />
        </a>
        <a href="https://apps.apple.com/hk/app/%E7%9D%87%E7%8F%BE%E7%92%B0%E4%BF%9D/id1582138587">
          <img className="badge apple" alt="立即下載 App Store" src={AppStoreBadge} />
        </a>
      </div>
    </div>
  );
};

export default PostSharePage;
